import "./GridLayRemoveScroll.css";
import GoogleMapIcon from "../GoogleMapAPI/assets/google-map-icon.png";
import houseIconGrid from "../GridLayoutDisplay/assets/houseIconGrid.png";
import townHouseIconGrid from "../GridLayoutDisplay/assets/townhouseGrid.png";
import condoIconGrid from "../GridLayoutDisplay/assets/condoIconGrid.png";
import commercialIconGrid from "../GridLayoutDisplay/assets/commercialIconGrid.png";
import landIconGrid from "../GridLayoutDisplay/assets/landIconGrid.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useContext, useEffect, useState, useRef } from "react";
import { BiSearch } from "react-icons/bi";
import { GridLoader, BeatLoader } from "react-spinners";
import FormatPrice from "./FormatPrice";
import FormatDateTime from "./FormatDateTime";
import ModalDetail from "../GoogleMapAPI/ModalDetail";
import LogoPostperty from "../GoogleMapAPI/assets/markerIcon/production/1. LOGO-Postperty.webp";

import { GalleryContext } from "../../../../App";
import { motion } from "framer-motion";
const GridLayoutDisplay = ({
  statusHalfGridMap,
  setStatusHalfGridMap,
  statusCloseMap,
  SetStatusCloseMap,
  dataSearchValue,
  setSearchValue,
  dataDisplay,
  setDataDisplay,
  setHeightStep,
  heightStep,
  mainDataModal,
  setMainDataModal,
}) => {
  console.log("dataDisplay", dataDisplay);

  const [savePositionBeforeUserExitPage, setSavePositionBeforeUserExitPage] =
    useState(sessionStorage.getItem("positionGridLayoutBeforeSeeDetail"));
  const [amountAsset, setAmountAsset] = useState(0);
  const [textSearchValue, setTextSearchValue] = useState("");
  const [isDownloadingDataAsset, setIsDownloadingDataAsset] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [PopUpPolicyCookies, setPopUpPolicyCookies] = useState(
    localStorage.getItem("firstComeReadCookiePolicyOnGridLayoutPage") === null
  );
  const { isGalleryOpen, setIsGalleryOpen, isClickContactModalDetail, setIsClickContactModalDetail } = useContext(GalleryContext)
  const typeIconGrids = {
    house: houseIconGrid,
    townhouse: townHouseIconGrid,
    condo: condoIconGrid,
    land: landIconGrid,
    commercial: commercialIconGrid,
  };


  // Framer motion ปุ่ม switch toggle หน้า grid
  const initialTop = 8 * 16; // 8rem converted to pixels (assuming 1rem = 16px)
  const dragDistance = 450; // Allow dragging up and down by 50 pixels
  const [userBlurSearch, setUserBlurSearch] = useState(false);

  const dragUpDistance = 70; // Allow dragging up by 170 pixels
  const dragDownDistance = 420; // Allow dragging down by 450 pixels
  const elementRef = useRef(null);
  const [constraints, setConstraints] = useState({ left: 0, right: 0, top: 0, bottom: 0 });

  useEffect(() => {
    const updateConstraints = () => {
      if (elementRef.current) {
        const { offsetWidth } = elementRef.current;
        setConstraints({
          left: 0,
          right: window.innerWidth - (offsetWidth + 19),
          top: -dragUpDistance,
          bottom: dragDownDistance,
        });
      }
    };

    updateConstraints();
    window.addEventListener("resize", updateConstraints);

    return () => {
      window.removeEventListener("resize", updateConstraints);
    };
  }, [dragUpDistance, dragDownDistance]);

  

  const changeStatusMap = () => {
    SetStatusCloseMap(false);
    setStatusHalfGridMap(true);
    localStorage.setItem("tempSwitchToMarkerOfGoogleMap", false);

    // Update the URL with the new parameter
    const url = new URL(window.location);
    url.searchParams.set("gridDisplay", "false");
    window.history.pushState({}, '', url);
  };


  useEffect(() => {
    setTimeout(
      () =>
        window.scrollTo(
          0,
          parseInt(
            sessionStorage.getItem("positionGridLayoutBeforeSeeDetail") || "0",
            10
          )
        ),
      1111
    );
    const statusUserClickToNavigation = localStorage.getItem(
      "tempSwitchToMarkerOfGoogleMap"
    );
    if (statusUserClickToNavigation === "true") {
      changeStatusMap();
    }
  }, []);

  useEffect(() => {
    const oldPosition = sessionStorage.getItem(
      "positionGridLayoutBeforeSeeDetail"
    );
    if (oldPosition !== null) {
      window.scrollTo({ top: oldPosition, behavior: "smooth" });
      sessionStorage.removeItem("positionGridLayoutBeforeSeeDetail");
      sessionStorage.removeItem("statusCloseMap");
    }
  }, [statusCloseMap]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        !loading &&
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 600
      ) {
        setLoading(true);
        setPage((prevPage) => prevPage + 1);
        getMultipleAssetWithPage();
      }
    }, 300);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const getMultipleAssetWithPage = () => {
    const apiUrl = `${process.env.REACT_APP_API}/asset/getMultipleAsset?page=${page}`;
    axios
      .get(apiUrl)
      .then((res) => {
        const data = res.data.assets;
        const totalAsset = res.data.statistic.total.total_assets;
        setAmountAsset(totalAsset);
        if (data.length !== 0) {
          setDataDisplay((prevData) => {
            const uniqueData = [...prevData, ...data].filter(
              (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            );
            return uniqueData;
          });
        }
        setLoading(false);
        setTimeout(() => setIsDownloadingDataAsset(false), 1500);
      })
      .catch(() => setLoading(false));

  };

  useEffect(() => {
    getMultipleAssetWithPage();
  }, []);

  const handleValueSearch = (e) => setTextSearchValue(e.target.value);

  const handleSearchValueClicked = () => {
    console.log('Handle search clicked')
    console.log(`Search value: ${textSearchValue}`)
    if (textSearchValue.length === 0) {
      setSearchValue("");
    } else if (textSearchValue.length > 1) {
      setSearchValue(textSearchValue);
    }
  };

  const saveScrollPosition = (linkRedirect) => {
    const scrollPosition = window.scrollY;
    setSavePositionBeforeUserExitPage(scrollPosition);
    sessionStorage.setItem("positionGridLayoutBeforeSeeDetail", scrollPosition);
    sessionStorage.setItem("statusCloseMap", true);
    localStorage.setItem("isNewTab", "true");
    window.open(linkRedirect, "_blank");
  };
  function formatNumberWithCommas(value) {
    if (typeof value === "string") {
      // Remove commas from the value if it already has them
      value = value.replace(/,/g, "");
    }

    // Parse the value as a number
    const numberValue = parseFloat(value);

    // Check if the parsed value is a valid number
    if (!isNaN(numberValue)) {
      // Format the number with commas
      return numberValue.toLocaleString();
    }

    // Return the original value if it couldn't be formatted
    return value;
  }
  const handleOpenDetailPost = (linkDetails) => saveScrollPosition(linkDetails);

  const handleSwitchToGoogleMapAndOpenMarker = (idAsset) => {
    axios
      .post(`${process.env.REACT_APP_API}/fetchdata/get-latlng`, {
        asset_id: idAsset,
      })
      .then(async (res) => {
        const idMarker = res.data.id;
        await localStorage.setItem(
          "positionBeforeExitedPage",
          JSON.stringify(res.data)
        );
        await sessionStorage.setItem(
          "confirmShowInfoWindowWithPosition",
          idMarker
        );
        if (window.innerWidth < 822) {
          changeStatusMap();
        } else {
          SetStatusCloseMap(false);
          setStatusHalfGridMap(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleConfirmPolicyCookies = () => {
    setPopUpPolicyCookies(false);
    localStorage.setItem("firstComeReadCookiePolicyOnGridLayoutPage", "false");
  };
  
  const token = localStorage.getItem("token");
  // const getDetailMarker = (id) => {
  //   axios
  //     .get(`${process.env.REACT_APP_API}/product/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {

  //       console.log(res.data);

  //     })
  //     .catch((err) => {
  //       console.log(err.response.data);

  //     });
  // };


  const getDetailMarker = (id) => {
    axios
      .get(`${process.env.REACT_APP_API}/product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data.asset)
        // console.log(res.data.asset);
        console.log(res.data);
        // if(res.data){
        // }
        setMainDataModal(res.data.asset);
      })
      .catch((err) => {
        console.log(err.response.data);
        //if localStorage is not have id asset data for show detail in marker detail remove from local storage
        // localStorage.removeItem("positionBeforeExitedPage")
        // setSelectedMarker("");
      });
  };
  async function handleItemOnClick(itemData) {
    console.log("handleItemOnClick", itemData);
    await getDetailMarker(itemData.id)
    setHeightStep("half")

    // Set URL parameter
    const url = new URL(window.location);
    url.searchParams.set('card', 'half');
    url.searchParams.set('aid', itemData.id);
    if (itemData.lat !== undefined) {
      url.searchParams.set('lat', itemData.lat);
    }
    if (itemData.lng !== undefined) {
      url.searchParams.set('lng', itemData.lng);
    }
    window.history.pushState({}, '', url);
  }

  const renderGrid = (post) => (
    <article
      key={post.id}
      onClick={() => handleItemOnClick(post)}
      className={`flex max-w-xl w-full flex-col items-start justify-between mx-auto rounded-xl cursor-pointer hover:scale-105 ${post.sold ? 'opacity-50' : ''}`}
      style={{ boxShadow: "0 4px 6px rgba(173, 216, 230, 0.8)" }}
    >
      <div
        className={`flex max-w-xl flex-col ${post.status ? "opacity-30" : "rounded"
          }`}
      >
        <div className="w-full h-60 max-h-60 relative">
          <div className="absolute top-3 left-4 bg-white rounded-full w-24 opacity-80">
            <p className="text-center">{post.type_post.Type.join("/")}</p>
          </div>
          <img
            className="absolute top-[-4px] left-[-2px] w-14 h-14"
            src={typeIconGrids[post.type]}
            alt=""
          />
          {post.sold && (
            <img
              className="absolute left-1/2 top-1/2 transform -translate-x-1/2 w-full h-full -translate-y-1/2 z-10"
              src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/icon/SOLD%20OUT.svg"
              alt="sold-out-icon"
            />
          )}
          <div
            className={`${post.imperfect_asset
              ? "hidden"
              : "absolute bottom-2 right-1/2 transform translate-x-1/2 bg-white rounded-full w-11/12 opacity-80 border"
              }`}
          >
            <p className="text-center">
              {post.sell_price !== null ? (
                <span className="text-blue-800">
                  {formatNumberWithCommas(post.sell_price)}
                </span>
              ) : post.rent_price !== null ? (
                formatNumberWithCommas(post.rent_price)
              ) : (
                formatNumberWithCommas(post.sellout_price)
              )}{" "}
              บาท
            </p>
          </div>
          <div
            className={`${post.imperfect_asset
              ? "absolute bottom-2 right-1/2 transform translate-x-1/2 bg-white rounded-full w-11/12 opacity-80"
              : "hidden"
              }`}
          >
            <p className="text-center">รายละเอียดติดต่อผู้ขาย</p>
          </div>
          <Link target="_blank" to={`${post.media.vr_url}`}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/5510/5510739.png"
              alt="360"
              className={`${post.media.vr_url
                ? "w-10 h-10 absolute top-2 right-2 bg-white p-1 rounded-full shadow-xl cursor-pointer"
                : "hidden"
                }`}
            />
          </Link>
          <Link target="_blank" to={`${post.media.YoutubeURL}`}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/3665/3665173.png"
              alt="360"
              className={`${post.media.YoutubeURL
                ? "w-10 h-10 absolute top-14 right-2 bg-white p-1 rounded-full shadow-xl cursor-pointer"
                : "hidden"
                }`}
            />
          </Link>
          <img
            src="https://cdn-icons-png.flaticon.com/512/6401/6401832.png"
            alt="360"
            className="w-10 h-10 absolute bottom-12 right-2 bg-white p-1 rounded-full shadow-xl cursor-pointer"
            onClick={() => handleSwitchToGoogleMapAndOpenMarker(post.id)}
          />
          <div className="w-full h-full">
            <img
              src={post.images_url.AssetImagesURL[0]}
              className="aspect-square object-cover w-full h-full rounded-xl z-10 md:aspect-video"
              loading="lazy"
              alt=""
            />
          </div>
        </div>
        <div className="hidden md:flex items-center gap-x-4 text-xs p-2 bg-white rounded-lg">
          <span className="text-gray-600">วันเดือนปี</span>
          <time className="text-blue-900 font-semibold">
            {<FormatDateTime isoDateString={post.date} />}
          </time>
          <a
            href={`/product/${post.id}`}
            className="relative z-10 rounded-full bg-blue-100 px-4 py-1 font-medium text-blue-700 hover:bg-blue-200 transition-colors duration-200"
            style={{ fontSize: "9px" }}
          >
            {post.id}
          </a>
        </div>
        <Link
        // onClick={() => handleOpenDetailPost(`/product/${post.id}`)}
        // to={`/product/${post.id}`}
        >
          <div className="group relative">
            <h3 className="mt-3 text-md font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
              <div className="md:hidden">
                <p className="text-blue-800">{post.title.slice(0, 25)}...</p>
                <span className="text-gray-500" style={{ fontSize: '13px' }}>
                  {post.description.slice(0, 30)}...
                </span>
              </div>
              <div className="hidden md:block text-lg">
                {post.title.slice(0, 18)}...
              </div>
            </h3>
            <div className="md:flex md:flex-col w-full h-38">
              <p className="hidden md:block">
                {post.description.slice(0, 38)}...
              </p>
              <div
                className={`${post.land.living_size
                  ? "w-full h-auto mx-auto grid grid-cols-12 gap-1 mt-2.5"
                  : "hidden"
                  }`}
              >
                <div className="grid col-span-3 h-10 lg:col-span-6 2xl:col-span-2 max-sm:col-span-6">
                  <div className="grid grid-cols-12">
                    <div className="col-span-7 flex justify-center items-center">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/3030/3030392.png"
                        alt="bedroom-Icon"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="col-span-4 flex justify-center items-center">
                      {post.rooms.Bedroom}
                    </div>
                  </div>
                </div>
                <div className="grid col-span-3 lg:col-span-6 2xl:col-span-2 max-sm:col-span-6 h-10">
                  <div className="grid grid-cols-12">
                    <div className="col-span-7 flex justify-center items-center">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/3130/3130230.png"
                        alt="batroom-Icon"
                        className="w-6 h-6"
                      />
                    </div>
                    <div className="col-span-3 flex justify-center items-center">
                      {post.rooms.Bathroom}
                    </div>
                  </div>
                </div>
                <div className="grid h-10 col-span-6 lg:col-span-6 2xl:col-span-4">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex justify-center items-center">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/1673/1673158.png"
                        alt="bedroom-Icon"
                        className="w-4 h-4"
                      />
                    </div>
                    <div className="col-span-7 flex justify-center items-center ml-1.5">
                      {post.land.living_size !== "0"
                        ? post.land.living_size
                        : post.land.total_size}
                      {post.land.living_unit === "ตารางเมตร" ? (
                        <p className="">ตร.ม</p>
                      ) : (
                        <p> ตร.ว</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden lg:col-span-6 2xl:col-span-4 h-10 md:hidden">
                  <div className="grid grid-cols-12">
                    <div className="col-span-2 flex justify-center items-center">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/4305/4305629.png"
                        alt="bedroom-Icon"
                        className="w-6 h-6"
                      />
                    </div>
                    <div className="col-span-10 flex justify-center items-center">
                      {post.type_post.Type.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${post.land.rai
                  ? "w-full h-auto mx-auto grid grid-cols-12 gap-1 mt-2.5"
                  : "hidden"
                  }`}
              >
                <div className="grid col-span-3 h-10 lg:col-span-6 2xl:col-span-2 max-sm:col-span-6">
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 flex justify-center items-center">
                      {post.land.rai} ไร่
                    </div>
                  </div>
                </div>
                <div className="grid colf-span-3 lg:col-span-6 2xl:col-span-2 max-sm:col-span-6 h-10">
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 flex justify-center items-center">
                      {post.land.ngan} งาน
                    </div>
                  </div>
                </div>
                <div className="grid h-10 col-span-6 lg:col-span-6 2xl:col-span-4">
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 flex justify-center items-center">
                      {post.land.wah} ตร.ว
                    </div>
                  </div>
                </div>
                <div className="hidden md:grid lg:col-span-6 2xl:col-span-4 h-10">
                  <div className="grid grid-cols-12">
                    <div className="col-span-2 flex justify-center items-center">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/4305/4305629.png"
                        alt="bedroom-Icon"
                        className="w-6 h-6"
                      />
                    </div>
                    <div className="col-span-10 flex justify-center items-center">
                      {post.type_post.Type.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="hidden relative mt-2 md:flex items-center gap-x-4">
          <img
            src={post.user_data.user.profile_image}
            alt=""
            className="h-10 w-10 rounded-full bg-gray-50"
          />
          <div className="text-sm leading-6">
            <p className="font-semibold text-gray-900">
              <a href="#" className="flex">
                <p>{post.user_data.user.uname}</p>
                {post.user_data.user.verify_level === 3 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#00FF00"
                    strokeWidth="2"
                    fill="none"
                    width="23"
                    height="23"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#888"
                    strokeWidth="2"
                    fill="none"
                    width="23"
                    height="23"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
              </a>
            </p>
            <p className="text-gray-600">{post.role}</p>
          </div>
        </div>
      </div>
    </article>
  );
  //

  console.log("is gallery status", isGalleryOpen)


  return (

    <div className={`bg-white lg:snap-y lg:snap-mandatory lg:h-full border-l overflow-hidden ${isGalleryOpen ? "md:hidden " : ""}`}>
      <div className="mx-auto relative">

        {/* Hide ask for cookie policy because it's bug */}

        {/* {PopUpPolicyCookies && (
          <div className="fixed bottom-20 h-[170px] w-full bg-postperty-linear-light text-white z-40 md:w-full border">
            <p className="w-full p-2.5 h-[100px]">
              เว็บไซต์นี้มีการใช้งานคุกกี้เพื่อให้ท่านสามารถใช้บริการได้อย่างต่อเนื่องและอำนวยความสะดวกในการใช้งานเว็บไซต์
              รวมถึงช่วยให้เราปรับปรุงเนื้อหาตรงความต้องการของคุณ
            </p>
            <div
              className="w-10/12 mx-auto rounded-full h-10 bg-white text-black flex justify-center items-center mt-6"
              onClick={handleConfirmPolicyCookies}
            >
              ยอมรับ
            </div>
          </div>
        )} */}

        {/* Fix bug การด์ ไอแพด ipad bug */}

        {statusCloseMap && ( // Properly use logical AND operator for conditional rendering
          <div className="w-full h-full">
            {mainDataModal !== null ? (
              <ModalDetail
                dataMainModal={mainDataModal}
                id={mainDataModal.id}
                setHeightStep={setHeightStep}
                heightStep={heightStep}
                statusComponent={'grid'}
              />
            ) : null}
          </div>
        )}
        <div className="mx-auto lg:px-2.5">
          <div
            className={`w-10 h-10 mx-auto fixed rounded-full top-[9rem] left-5 z-30 items-center justify-center md:right-10 ${statusHalfGridMap ? "hidden" : ""
              }`}
          >
            <motion.div
              ref={elementRef}
              className="fixed left-1 h-auto cursor-pointer bg-blue-600 bg-opacity-70 p-4 rounded-md shadow-md"
              style={{ top: '8rem', width: '4.5rem' }}
              onClick={changeStatusMap}
              drag
              dragConstraints={constraints}
            >
              <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center drop-shadow-xl">
                <img src={GoogleMapIcon} className="w-6 h-6" alt="Google Map Icon" />
              </div>
              <p className="text-xs text-white mt-1 text-center">สลับมุมมอง</p>

            </motion.div>
          </div>
          <div className="flex justify-center max-w-7xl mx-auto bg-line">
            <div className="w-10/12 lg:mx-0">
              {!isDownloadingDataAsset && (
                <div>
                  
                  <div className="w-full h-[52px] bg-white rounded-full grid grid-cols-12 md:hidden mt-4 z-20 shadow-lg px-2">
                    <Link
                      className="col-span-3 flex items-center justify-center"
                      to={`/`}
                    >
                      <div className="flex items-center justify-center">
                        <img
                          src={LogoPostperty}
                          className="w-10 h-10"
                          alt="logo-postperty"
                        />
                      </div>
                    </Link>
                    <div className="col-span-6 rounded-full relative flex items-center shadow-md">
                      <input
                        onChange={handleValueSearch}
                        onBlur={() => setUserBlurSearch(true)}
                        type="text"
                        className="w-full h-full outline-0 text-center rounded-full"
                        placeholder="ค้นหาด้วยคีย์เวิร์ด"
                      />
                      <BiSearch
                        onClick={handleSearchValueClicked}
                        className={
                          textSearchValue.length > 0
                            ? "absolute bg-blue-500 top-1/2 right-2 transform -translate-y-1/2 w-8 h-8 rounded-full text-white p-1 cursor-pointer"
                            : "absolute top-1/2 right-2 transform -translate-y-1/2 w-6 h-6 cursor-pointer"
                        }
                      />
                    </div>
                  </div>

                  
                  {/* <h2
                    className={`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center mt-4`}
                  >
                    รายการอสังหาริมทรัพย์
                  </h2> */}
                  <p className="text-center w-full">
                    {`จำนวนอสังหาฯ `}
                    <span className="text-blue-700">
                      {dataSearchValue.length > 0
                        ? dataSearchValue.length
                        : dataDisplay.length}
                    </span>
                    {` รายการ`}
                  </p>
                  {/* <div className="w-10/12 h-12 rounded-full grid grid-cols-12 border mt-6 max-sm:shadow-xl fixed top-[47px] mx-auto z-30 bg-white md:static md:w-full md:rounded-l md:hidden">
                    <div className="col-span-10 rounded-full relative">
                      <input
                        onChange={handleValueSearch}
                        type="text"
                        className="w-full h-full outline-0 text-center"
                        placeholder="ค้นหา"
                      />
                      <BiSearch
                        onClick={handleSearchValueClicked}
                        className={`${
                          textSearchValue.length > 0
                            ? "absolute bg-blue-500 top-1/2 right-2 transform -translate-y-1/2 w-8 h-8 rounded-full text-white p-1 cursor-pointer"
                            : "absolute top-1/2 right-2 transform -translate-y-1/2 w-6 h-6 cursor-pointer"
                        }`}
                      />
                    </div>
                  </div> */}
                </div>
              )}
              {isDownloadingDataAsset && (
                <div className="flex justify-center items-center h-screen">
                  <GridLoader color="#000" />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center w-full z-50">
            <div
              className={`${statusHalfGridMap
                ? "grid max-w-3xl grid-cols-12 gap-x-2.5 gap-y-16 max-sm:pt-16 md:gap-y-2.5 lg:mx-0 lg:max-w-7xl lg:grid-cols-2 overflow-y-auto h-[95vh]"
                : "grid max-w-3xl grid-cols-2 gap-x-1 gap-y-2 pt-10 max-sm:pt-16 lg:mx-0 lg:max-w-7xl sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 overflow-y-auto h-[700px]"
                }`}
            >
              {dataSearchValue.length === 0
                ? dataDisplay.map((post) => renderGrid(post))
                : dataSearchValue.map((post) => renderGrid(post))}
            </div>
            {/* <h1 className="flex flex-col justify-center items-center w-full h-[100px] max-h-screen">
              <BeatLoader color="#000" />
            </h1> */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default GridLayoutDisplay;
