import axios from "axios";
import { MdContentCopy } from "react-icons/md";
import Swal from "sweetalert2";
import { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";
import "canvas-toBlob";
import { Link } from "react-router-dom";

const InviteFriend = () => {
  const [uName, setUName] = useState("");
  const inviteLink = `https://postperty.com/register?ID=${uName}`;

  const qrCodeCanvasRef = useRef(null);

  useEffect(() => {
    const cookie = Cookies.get("jwt");
    const token = `${cookie}`;
    axios
      .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { uname } = res.data;
        setUName(uname);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(()=>{
    getBrochure()
  },[])

  const copyLinkInvite = async () => {
    await navigator.clipboard.writeText(inviteLink);
    Swal.fire(
      "คัดลอกสำเร็จ",
      "สามารถนำลิงค์นี้แชร์ให้เพื่อนของคุณได้",
      "success"
    );
  };

  const saveQRCodeImage = () => {
    if (qrCodeCanvasRef.current) {
      const canvas = qrCodeCanvasRef.current.querySelector("canvas");
      if (canvas) {
        canvas.toBlob((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `postperty_invite_link_${uName}.png`;
          link.click();
        }, "image/png");
      }
    }
  };

  const [dataBrochure , setDataBrochure] = useState('')
  function getBrochure(){
    axios.post(`${process.env.REACT_APP_API}/event/getBrochure`,{
      "nothing" : ''
    },{
      headers: {
        Authorization: `Bearer ${Cookies.get("jwt")}`,
      },
    }).then(res=>{
      console.log(res.data)
      setDataBrochure(res.data.BrochureURL)
    }).catch(err=>{
      console.log(err)
    })
  }


  const [checkPhone, setCheckPhone] = useState({
    phone: null,
  })

  const [textCheckPhoneNumber, setTextCheckPhoneNumber] = useState(
    "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
  );

  const checkPhoneNumber = (number) => {
    console.log("Correct phone number", number);
    if (number !== "" && number.length === 10) {
      axios
        .post(`${process.env.REACT_APP_API}/user/checkPhoneNumber`, {
          checkPhoneNumber: number,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.Status === true) {
            // setShowAlert(true);
            setTextCheckPhoneNumber("เบอร์นี้สมัครสมาชิกแล้ว");
          } else {
            setTextCheckPhoneNumber("เบอร์นี้ยังไม่เป็นสมาชิก สามารถชวนได้");

          }
        })
        .catch((err) => { });
    }
    if (number.length !== 10) {
      console.log("กรุณากรอกให้ครบ 10 หลัก");
      setTextCheckPhoneNumber("กรุณากรอกเบอร์โทร 10หลัก");
    }
  };

  const inputValue = (name) => (e) => {
    setCheckPhone({ ...checkPhone, [name]: e.target.value });
  };

  function handleCopyLinkVideo(){
    navigator.clipboard.writeText("https://www.youtube.com/watch?v=CIm8ZCTFXOU");
    Swal.fire(
      "คัดลอกลิงค์Videoสำเร็จ",
      "สามารถนำลิงค์นี้แชร์ให้เพื่อนของคุณได้",
      "success"
    );
  }
  return (
    <div className="flex flex-col h-auto w-full bg-postperty-linear-light md:flex md:flex-col ">
      <div className="w-full mt-16 ">
        <Link to={`/profiles`}>
          <div className="w-full h-9  relative flex  ">
            <button
              className="bg-white w-9  h-6  absolute left-9 top top-1 rounded-3xl  "

            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
                alt="backword"
                className="w-4 h-4  m-auto"
              ></img>
            </button>
            <h1 className="text-white w-full text-center mt-auto mb-auto text-xl">
              แนะนำเพื่อน
            </h1>
          </div>
        </Link>

      </div>

      <div className="md:grid md:grid-cols-12 md:min-h-screen  md:w-9/12 md:mx-auto">

        <div className="md:col-span-6 md:min-h-fitf">
          <div className=" md:w-fit md:h-auto md:mx-auto">
            <div className="flex flex-col w-64 h-30 mr-auto ml-auto mt-12 md:mt-0">
              <h2 className="text-5xl font-bold text-white">แนะนำเพื่อน</h2>
              <h1 className="text-5xl font-bold text-white">POSTPERTY</h1>
            </div>

            <div className="h-auto mt-12">
              
              <div className="relative w-full rounded-2xl p-2.5  h-auto  mt-2.5  bg-white mx-auto">
              <h1 className="w-full text-center ">ขั้นตอนที่ 1 : ตรวจสอบเบอร์โทรศัพท์เพื่อนของคุณ</h1>

                <div className="w-full flex flex-col my-2.5 justify-center items-center">
                  <input
                    type="text"
                    placeholder="เบอร์โทรศัพท์"
                    onChange={(e) => {
                      inputValue("phone")(e);
                      checkPhoneNumber(e.target.value);
                    }}
                    className="borer w-11/12 h-12 rounded-full ounded mt-3 text-center bg-half-light-2 outline-0 md:w-3/4 text-base placeholder-gray-300"
                  ></input>
                  <p
                    className={
                      textCheckPhoneNumber == "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก" ? 'text-gray-500'
                      : textCheckPhoneNumber == "เบอร์นี้ยังไม่เป็นสมาชิก สามารถชวนได้"
                        ? "text-green-500"
                        
                        : "text-red-500"
                    }
                  >
                    {textCheckPhoneNumber}
                  </p>

                </div>

                <h1 className="w-full text-center">ขั้นตอนที่ 2 : คัดลอกลิงค์นำส่งต่อให้เพื่อนของคุณ</h1>

                <div className="p-2 mt-1.5 w-96 h-23 flex mr-auto ml-auto justify-center ">
                  <button
                    className="text-base text-black-400 w-72 h-14 rounded-full  bg-half-light-f5"
                  >
                    <h1 className="">{inviteLink}</h1>
                  </button>
                  <button
                    className="w-24 h-15 bg-white rounded-full bg-postperty-linear-light text-white text-2xl ml-1 flex flex-col justify-center items-center"
                    onClick={copyLinkInvite}
                  >
                    <MdContentCopy className="mr-auto ml-auto" />
                    <p className="text-sm">copy link</p>
                  </button>

                </div>


                <div className=" flex flex-col h-full justify-center items-center mt-4">
                  <p >User ID คุณคือ</p>
                  <h1 className=" text-4xl mt-2.5  rouned-full">{uName}</h1>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="w-full  flex justify-center items-center mt-2.5 md:mt-0 md:col-span-6 ">
          <div className="w-96 ml-auto mr-auto h-auto p-2.5  bottom-0 bg-white rounded-2xl md:h-auto  md:mt-24 ">
            <div className="w-full flex flex-col justify-center items-center h-60 mt-9">
              <div ref={qrCodeCanvasRef}>
                <QRCode value={inviteLink} bgColor="#fff" size={170} />
              </div>
              <button
                onClick={saveQRCodeImage}
                className="mt-6 bg-postperty w-80 h-11 rounded-full"
              >
                บันทึก QR Code
              </button>
            </div>
          </div>
        </div>  

        <div className="w-full  h-auto p-4 bg-white  mt-2.5 rounded-xl  md:col-span-12 md:mt-36 ">
        <div className="relative h-auto w-full  rounded-xl mt-2.5">
            <img className="object-cover  w-full rounded-xl  md:w-2/3 md:mx-auto" src={dataBrochure}/>
            <p class="absolute left-1/2 tranform -translate-x-1/2 bottom-2.5  opacity-45 rounded-full p-2 w-10/12  text-center text-white cursor-pointer bg-postperty-linear-light hover:bg-black md:w-1/4" onClick={()=>window.open(dataBrochure)}>คลิกดาวน์โหลดโบรชัวร์ด้านหน้า</p>
          </div>
          <div className="relative h-auto w-full  rounded-xl mt-2.5 md:col-span-12">
            <img className="object-cover  w-full rounded-xl   md:w-2/3  md:mx-auto" src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/brochure/back/โบรชัวร์_postperty_ด้านหลัง.png"/>
            <p class="absolute left-1/2 tranform -translate-x-1/2 bottom-2.5  opacity-45 rounded-full p-2 w-10/12  text-center text-white cursor-pointer bg-postperty-linear-light hover:bg-black md:w-1/4" onClick={()=>window.open("https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/brochure/back/โบรชัวร์_postperty_ด้านหลัง.png")}>คลิกดาวน์โหลดโบรชัวร์ด้านหลัง</p>
          </div>
        </div>
        <div className=" w-10/12 mx-auto h-12 rounded-full flex justify-center items-center cursor-pointer bg-green-500 text-white mt-6 md:col-span-12" onClick={handleCopyLinkVideo}>คัดลอกวีดีโอแนะนำ postperty ส่งให้เพื่อน</div>
 
        <div className="w-full h-[120px]"></div>
      </div>
    </div>
  );
};

export default InviteFriend;
