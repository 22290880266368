import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiRightArrow, BiLeftArrow } from "react-icons/bi";
import { motion } from "framer-motion";

export default function MembersLayerData() {
  const session_id = Cookies.get("jwt");
  const [dataDetailLayer, setDataDetailLayer] = useState([]);
  const [dataDetailPage, setDataDetailPage] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const dataValue = url.searchParams.get("data");
    setDataDetailPage(dataValue ? parseInt(dataValue) : 1);
  }, []);

  useEffect(() => {
    if (dataDetailPage !== null) {
      axios
        .get(
          `${process.env.REACT_APP_API}/mlm/invited-detail?layer=${dataDetailPage}`,
          {
            headers: {
              Authorization: `Bearer ${session_id}`,
            },
          }
        )
        .then((res) => {
          setDataDetailLayer(res.data.invitees || []);
        })
        .catch((error) => {
          console.error(error);
          setDataDetailLayer([]);
        });
    }
  }, [dataDetailPage, session_id]);

  return (
    <div
      className="relative w-full h-screen mx-auto flex flex-col items-center bg-cover bg-center"
      style={{ backgroundImage: `url('https://images.pexels.com/photos/2833037/pexels-photo-2833037.jpeg')` }}
    >
      <div className="w-full bg-white bg-opacity-80 shadow-md py-4 flex items-center justify-between px-4 md:px-12">
        <Link
          to={
            dataDetailPage === 1
              ? "/members/layer"
              : `/members/layer/desc?data=${dataDetailPage - 1}`
          }
          onClick={() => setDataDetailPage((prev) => Math.max(prev - 1, 1))}
          className="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md"
        >
          <BiLeftArrow className="w-6 h-6 text-blue-600" />
        </Link>
        <p className="text-blue-600 text-md font-semibold">
          Downline ชวนสมัครชั้นที่ {dataDetailPage} มี {dataDetailLayer.length} คน
        </p>
        <Link
          to={
            dataDetailPage === 10
              ? ""
              : `/members/layer/desc?data=${dataDetailPage + 1}`
          }
          onClick={() => setDataDetailPage((prev) => Math.min(prev + 1, 10))}
          className={`flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md ${
            dataDetailPage === 10 ? "hidden" : ""
          }`}
        >
          <BiRightArrow className="w-6 h-6 text-blue-600" />
        </Link>
      </div>

      <div className="overflow-y-auto h-[700px] mb-12 mt-16 w-full px-4">
        {dataDetailLayer.length > 0 ? (
          dataDetailLayer.map((data, index) => (
            <motion.div
              key={index}
              className="relative grid grid-cols-12 w-full max-w-md md:max-w-2xl h-24 rounded-2xl border shadow-lg mt-2.5 bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <div className="col-span-5 flex justify-center items-center">
                <img
                  src={data.profile_image}
                  className="w-16 h-16 object-cover mx-auto rounded-full border border-blue-500"
                  alt="img_user"
                />
              </div>
              <div className="col-span-7 flex flex-col justify-center">
                <h1 className="text-blue-800 font-semibold">{data.uname}</h1>
                <p className="text-white absolute top-2 right-4 py-1 px-2 text-center border rounded-full bg-blue-300 border-blue-500">
                  คนที่ {index + 1}
                </p>
              </div>
            </motion.div>
          ))
        ) : (
          <motion.div
            className="flex flex-col items-center justify-center h-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex flex-col items-center justify-center bg-white bg-opacity-70 rounded-full p-12 backdrop-filter backdrop-blur-lg">
              <img
                src="https://cdn-icons-png.flaticon.com/512/8188/8188410.png"
                alt="No Data"
                className="w-24 h-24 mb-4"
              />
              <p className="text-blue-700 font-bold">ไม่มีข้อมูลสมาชิกในระดับนี้</p>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}
