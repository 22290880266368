import axios from "axios";
import { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import GetLatLngOnly from "../home/GoogleMapAPI/GetLatLngOnlyPure";
import Swal from "sweetalert2";
import ImagesUpload from "../../post/ImagesUpload";
import Cookies from "js-cookie";
import localforage from "localforage";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { BeatLoader } from "react-spinners";

// Initialize localForage
const dbName = "DataLead";
localforage.config({
  name: dbName,
  storeName: "Data_Lead_Asset", // Should be alphanumeric, with underscores.
  description: "Store(lat,lng,phone_number,description,images)",
});

const LeadAssets = () => {
  // Validate Phone Number
  const [textCheckPhoneNumber, setTextCheckPhoneNumber] = useState(
    "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
  );
  const [errorPopUp , setErrorPopUp] = useState(false);
  const [errorMsgPopUp , setErrorMsgPopUp] = useState('');

  const checkPhoneNumber = (number) => {
    if (number.length < 10) {
      console.log("กรุณากรอกให้ครบ 10 หลัก");
    }

    if (number.length > 10) {
      setTextCheckPhoneNumber("กรุณาระบุเบอร์โทรแค่ 10หลัก");
    }

    if (number !== "" && number.length === 10) {
      axios
        .get(`${process.env.REACT_APP_API}/event/checkExistedlead/${number}`)
        .then((res) => {
          console.log(res.data.message);
          const message = res.data.message;
          setTextCheckPhoneNumber(message);
          // if (res.data.Status === true) {
          //     setTextCheckPhoneNumber("เบอร์นี้เป็นสมาชิกแล้ว ส่งข้อมูลได้ แต่อาจไม่ได้รับค่า นายหน้า");
          // } else {
          //     setTextCheckPhoneNumber("เบอร์นี้ยังไม่ได้เป็นสมาชิก");
          // }
        })
        .catch((err) => {});
    }
  };

  const [upload, setUpload] = useState({
    lat: null,
    lng: null,
    phone_number: "",
    description: "",
    images: [],
    asset_type: "house",
    type_post: ["ขาย"],
  });
  const { lat, lng, phone_number, description, images, asset_type, type_post } =
    upload;

  const [isValidLocation, setIsValidLocation] = useState(false);

  // Store upload data in IndexedDB when it changes
  useEffect(() => {
    const storeUploadData = async () => {
      try {
        await localforage.setItem("uploadData", upload);
      } catch (err) {
        console.error("Error storing upload data", err);
      }
    };
    storeUploadData();
  }, [upload]);

  // Retrieve upload data from IndexedDB on first render
  useEffect(() => {
    const retrieveUploadData = async () => {
      try {
        const savedUploadData = await localforage.getItem("uploadData");
        if (savedUploadData) {
          setUpload(savedUploadData);
        }
      } catch (err) {
        console.error("Error retrieving upload data", err);
      }
    };
    retrieveUploadData();
  }, []);

  const removeUploadData =  async () => {
    try {
      await localforage.removeItem("uploadData");
      await Swal.fire("ส่งข้อมูลสำเร็จ", "", "success");
      setIsUploading(false);
      window.location.replace(`/home`);
    } catch (err) {
      console.error("Error removing upload data", err);
      setIsUploading(false);
    }
  };

  const ClearUploadDataLeadWhenError = async () => {
    try {
      await localforage.removeItem("uploadData");
      console.log("Upload data removed successfully");
      Swal.fire("ส่งข้อมูลสำเร็จ", "", "success");
      setIsUploading(false);
      window.location.replace(`/home`);
    } catch (err) {
      console.error("Error removing upload data", err);
      setIsUploading(false);
    }
  };

  const handleRemoveDataInIndexedDB = () => {
    localforage
      .dropInstance({
        name: dbName,
      })
      .then(() => {
        console.log(`Successfully removed database: ${dbName}`);
      })
      .catch((err) => {
        console.error(`Error removing database: ${dbName}`, err);
      });
  };

  useEffect(() => {
    if (phone_number.length < 10) {
      setTextCheckPhoneNumber(`กรุณากรอกเบอร์โทรศัพท์ 10 หลัก`);
    }
  }, [phone_number]);

  const inputValue = (name) => (e) => {
    setUpload({ ...upload, [name]: e.target.value });
  };

  // Google Map
  const [assetLocationLatitude, setAssetLocationLatitude] = useState(null);
  const [assetLocationLongitude, setAssetLocationLongitude] = useState(null);

  useEffect(() => {
    setUpload((prevUpload) => ({
      ...prevUpload,
      lat:
        assetLocationLatitude !== null
          ? assetLocationLatitude.asset_location_latitude
          : "",
      lng:
        assetLocationLongitude !== null
          ? assetLocationLongitude.asset_location_longitude
          : "",
    }));
  }, [assetLocationLatitude, assetLocationLongitude]);

  // Text Report
  const [lengthOfDetailPost, setLengthOfDetailPost] = useState(0);
  const handleDetailPostLimit = (e) => {
    const lengthOfDetail = e.target.value.length;
    const textOfDetail = e.target.value;
    const maxLimitPostLength = 999;

    if (lengthOfDetail <= maxLimitPostLength) {
      setLengthOfDetailPost(lengthOfDetail);
      setUpload((prevUpload) => ({
        ...prevUpload,
        description: textOfDetail,
      }));
    } else {
      Swal.fire({
        icon: "error",
        title: "เกินจำนวนตัวอักษรที่กำหนด",
        text: "คุณสามารถกรอกข้อความได้สูงสุด 1000 ตัวอักษร",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  // Scroll to Top
  const scrollToTop = () => {
  
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Image upload
  const [pageNumber, setPageNumber] = useState(1);

  const openImageUploads = () => {
    setPageNumber(1.5);
    scrollToTop();
  };

  const handleImageChange = (images) => {
    setUpload((prevUpload) => ({
      ...prevUpload,
      images,
    }));
  };

  // Upload data to dashboard admin
  const [isUploading, setIsUploading] = useState(false);
  const getToken = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };
  const handleLeadAssetSendToDashboardAdmin = () => {
    setIsUploading(true);
    let token = getToken();
    if (isValidLocation == false && typeof lat == "string") {
      Swal.fire("", "กรุณาปักหมุดหรือ เลือกไม่มีตำแหน่ง", "error");
      setIsUploading(false);
    } else {
      if (!isUploading) {
        axios
          .post(
            `${process.env.REACT_APP_API}/mlm/lead`,
            {
              lat: lat,
              lng: lng,
              phone_number: phone_number,
              description: description,
              images: images,
              asset_type,
              type_post,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data", //Important For Recive image[] ต้องมี
              },
            }
          )
          .then((res) => {
            const marker_data = res.data.data;
            const location = res.data.data.location;
            if (
              marker_data.location.lat !== null &&
              marker_data.location.lng !== null
            ) {
              localStorage.setItem(
                "positionBeforeExitedPage",
                JSON.stringify(marker_data)
              );
            }

            removeUploadData();

            console.log(res.data);
            console.log(res.data.data);

            //    This 2 line is set lat and lng for recommend asset when user check current location system recommend house around user
            localStorage.setItem("LatForRecommendedAssets", location.lat);
            localStorage.setItem("LngForRecommendedAssets", location.lng);

            // removeUploadData();
          })
          .catch(async (err) => {
            setIsUploading(false);
            if (err.response.data.Error == "Corrupted files detected") {
              Swal.fire({
                title: `รูปเกิดความเสียหาย (รูปที่ ${err.response.data.CorruptedFilePage}) ต้องการข้ามภาพที่เสียหรือไม่ ?`,
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: "ลบรูปเสียทั้งหมด",
                denyButtonText: "ขอตรวจสอบรูปภาพ",
                customClass: {
                  actions: "my-actions",
                  cancelButton: "order-1 right-gap",
                  confirmButton: "order-2",
                  denyButton: "order-3",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  // Filter out corrupted images
                  const filteredImages = images.filter(
                    (image, index) =>
                      !err.response.data.CorruptedFileIndex.includes(index)
                  );

                  // Update the images in the state
                  setUpload((prevState) => ({
                    ...prevState,
                    images: filteredImages,
                  }));
                } else if (result.isDenied) {
                }
              });
              // Swal.fire("ภาพเกิดความเสียหาย" , `กรุณาตรวจสอบรูปภาพที่ ${err.response.data.CorruptedFilePage}` , "error")
            } else {
             
              const errMsg = err.response.data.Error;
              if(errMsg == "กรุณากรอก เบอร์โทร ของป้ายประกาศขายที่ติดอยู่หน้าทรัพย์" || errMsg == "กรุณากรอก เบอร์โทร ของป้ายประกาศขายที่ติดอยู่หน้าทรัพย์"){
                Swal.fire('',errMsg , 'error')
                scrollToTop()
              };


              setErrorPopUp(true);
              setErrorMsgPopUp(err.response.data.Error)
              
              // Swal.fire({
              //   icon: "error",
              //   title: "เกิดข้อผิดพลาด",
              //   text: `${err.response.data.Error}`,
              // });
            }
          });
      }
    }
  };

  function handleCheckboxValidLocation() {
    setIsValidLocation(!isValidLocation);
    if (isValidLocation && lat === null && lng === null) {
      setUpload({ ...upload, lat: null, lng: null });
    }
  }

  const listTypeAssets = [
    { label: "บ้านเดี่ยว", key: "house", Col_Span: 4 },
    { label: "คอนโด", key: "condo", Col_Span: 4 },
    { label: "ทาวน์เฮ้าส์", key: "townhouse", Col_Span: 4 },
    { label: "ที่ดิน", key: "land", Col_Span: 4 },
    { label: "อาคารพาณิชย์", key: "commercial", Col_Span: 4 },
    { label: "โกดัง/โรงงาน", key: "other", Col_Span: 4 },

    // { label: "โกดัง/โรงงาน", key: "factory", Col_Span: 6 },
  ];

  useEffect(() => {
    sessionStorage.getItem("phoneNumOCR");
  });

  return (
    <div className="relative w-11/12 overflow-y-auto  h-auto  mx-auto md:w-8/12 mt-3">
      <div className="absolute  top-0  w-full h-12 bg-red-500 text-white text-center">{errorMsgPopUp}</div>
      <div className={pageNumber === 1 ? "w-full h-auto border " : "hidden"}>
        <p className="mt-12 ml-1 text-xl">แจ้งทรัพย์ให้ PostPerty (Lead)</p>

        <div className="w-full h-auto  p-2 rounded-xl my-2.5 border ">
          <p>
            ตรวจสอบ สถานะเจ้าของทรัพย์ <br />
            ด้วยเบอร์โทรที่ติดประกาศ <span className="text-red-500">*</span>
          </p>
          <form className="flex flex-col justify-center items-center">
            <div className=" flex flex-col justify-center items-center w-full h-full">
              <input
                id="phoneNumberInput" // Add ID to the input field
                type="text"
                // defaultValue={
                //     sessionStorage.getItem("phoneNumOCR") !== "undefined" && sessionStorage.getItem("phoneNumOCR") !== null
                //         ? sessionStorage.getItem("phoneNumOCR").replaceAll("-", "")
                //         : upload.phone_number
                // }
                placeholder="เบอร์โทรศัพท์"
                onChange={(e) => {
                  inputValue("phone_number")(e);
                  checkPhoneNumber(e.target.value); // Call checkPhoneNumber function here
                }}
                className="borer w-10/12 h-12 rounded-full ounded mt-3 text-center bg-half-light-2 outline-0 md:w-3/4 text-base placeholder-gray-300"
              />

              <p
                className={
                  textCheckPhoneNumber ===
                  "เบอร์โทรนี้ไม่ได้เป็นสมาชิก สามารถส่งข้อมูลเข้ามาได้"
                    ? "text-green-500  mt-3.5 w-3/4 text-center"
                    : textCheckPhoneNumber === "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
                    ? "text-black text-base opacity-40  text-center   mt-3.5"
                    : "text-red-500 text-center    mt-3.5  w-3/4"
                }
              >
                {textCheckPhoneNumber}
              </p>
            </div>
          </form>
        </div>

        <div className="w-full h-max flex flex-col  justify-center items-center border rounded-xl">
          <div className="w-full p-2 ">
            <ImagesUpload
              images={images}
              upload={upload}
              setUpload={setUpload}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
        <div className="border p-2 rounded-full  w-max mt-2.5  flex">
          <label>
            <input
              type="checkbox"
              checked={isValidLocation}
              onChange={handleCheckboxValidLocation}
            />
            <span className="ml-2.5">ไม่มีตำแหน่ง / ไม่ทราบตำแหน่ง</span>
          </label>
        </div>

        <div
          className={
            isValidLocation
              ? "hidden"
              : "w-full mt-2.5 p-6  h-[500px] md:h-[700px] mb-2.5 md:col-span-6 md:mb-0 relative"
          }
        >
          <div className="w-8 h-8 absolute right-[-10px] top-32 md:hidden">
            <MdKeyboardArrowUp className="w-8 h-8" />
          </div>
          <div className="w-8 h-8 absolute right-[-10px] top-[350px] md:hidden">
            <MdKeyboardArrowDown className="w-8 h-8" />
          </div>
          <GetLatLngOnly
            setAssetLocationLatitude={setAssetLocationLatitude}
            setAssetLocationLongitude={setAssetLocationLongitude}
            latLeadDataUserClickBeforeExitPage={lat}
            lngLeadDataUserClickBeforeExitPage={lng}
          />
        </div>

        <div className="w-full h-full grid grid-cols-12 gap-x-2.5 gap-y-1.5 p-2 border rounded-xl mt-2.5">
          <p className="col-span-12">ประเภทอสังหาฯ</p>
          {listTypeAssets.map((data, idx) => {
            const colSpanClass = `col-span-${data.Col_Span}`;
            const isSelected = data.key === asset_type;
            const backgroundClass = isSelected
              ? "bg-postperty-linear-light text-white"
              : "bg-half-light-f5 text-black";

            return (
              <div
                key={idx}
                className={`rounded-full w-full h-12 flex justify-center items-center cursor-pointer ${colSpanClass} ${backgroundClass}`}
                onClick={() => setUpload({ ...upload, asset_type: data.key })}
              >
                {data.label}
              </div>
            );
          })}
        </div>

        <div className="w-full h-24 grid grid-cols-12 gap-x-2.5 gap-y-1.5 p-2 border rounded-xl mt-2.5">
          <p className="col-span-12">ประเภทการขาย</p>
          {[
            { label: "ขาย", key: "sell", colSpan: 4 },
            { label: "เช่า", key: "rent", colSpan: 4 },
            { label: "เซ้ง", key: "sell_out", colSpan: 4 },
          ].map((data, idx) => {
            const colSpanClass = `col-span-${data.colSpan}`;
            const isSelected = type_post.includes(data.label);
            const backgroundClass = isSelected
              ? "bg-postperty-linear-light text-white"
              : "bg-half-light-f5 text-black";

            return (
              <div
                key={idx}
                className={`rounded-full w-full h-12 flex justify-center items-center cursor-pointer ${colSpanClass} ${backgroundClass}`}
                onClick={() => {
                  if (isSelected) {
                    setUpload({
                      ...upload,
                      type_post: type_post.filter(
                        (type) => type !== data.label
                      ),
                    });
                  } else {
                    setUpload({
                      ...upload,
                      type_post: [...type_post, data.label],
                    });
                  }
                }}
              >
                {data.label}
              </div>
            );
          })}
        </div>

        <div className="w-full h-auto border   ">
          {/*  Detail Post  */}
          <div className="w-full h-96  p-2    rounded-full mt-2.5 border  ">
            <h1 className="text-l my-2.5">
              รายละเอียด <span>{lengthOfDetailPost}/999</span>
            </h1>
            <textarea
              defaultValue={description}
              onChange={(e) => {
                // inputValue("detail_post");
                handleDetailPostLimit(e);
              }}
              className="w-full h-80 rounded-xl outline-0 bg-half-light-f5 p-4"
            />
          </div>
          {/* Detail Post  */}
        </div>

        <div className="w-full flex justify-center items-center my-2.5">
          {isUploading ? <BeatLoader color="#000" /> : null}
        </div>
        <button
          className="bg-red-700 w-full rounded-full h-12 text-white  mt-2.5 "
          onClick={() => handleLeadAssetSendToDashboardAdmin()}
        >
          {isUploading ? (
            <span>
              กำลังอัพโหลด... <span className="inline-block">⌛</span>
            </span>
          ) : (
            <span>ส่งข้อมูลให้ PostPerty</span>
          )}
        </button>
      </div>

      <div className="w-full h-[200px]"></div>
    </div>
  );
};

export default LeadAssets;
