import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export default function MyShareAsset() {
    const [expanded, setExpanded] = useState([]);
    const [myshareData , setMyShareData] = useState([]);
    const dataInitiated = [
        {
            first_line: 'ARX',
            amount_shared: 199,
            success_amount_shared: 90,
            second_shared: 'Manday',
            third_shared: 'Micle',
            amount_shared_another: 500,
            member_shared_another: ['member1', 'member2', 'member3', 'member4', 'member5', 'member6', 'member7', 'member8', 'member1', 'member2', 'member3', 'member4', 'member5', 'member6', 'member7', 'member8']
        },

        {
            first_line: 'ARX2',
            amount_shared: 229,
            success_amount_shared: 1000,
            second_shared: 'LOX',
            third_shared: 'LLL',
            amount_shared_another: 800,
            member_shared_another: ['member1', 'member2', 'member3', 'member4', 'member5', 'member6', 'member7', 'member8',]
        },


    ]

    const session_id = Cookies.get("jwt");
    useEffect(() => {
       axios.post(`${process.env.REACT_APP_API}/mlm/myshare`,{
        "get" : 'true'
       },{
        headers:{
            Authorization : `Bearer ${session_id}`
        }
       }).then((res)=>{
        console.log(res.data)
        // setMyShareData(res.data)
        
        setMyShareData(res.data.myshare)
       }).catch((err)=>{
        console.log(err)
       })
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])
    function handleDropDownListMember(index) {
        const newExpanded = [...expanded];
        newExpanded[index] = !newExpanded[index];
        setExpanded(newExpanded);

        console.log("Click DropDownListMember", index)

    }
    return (
        <div className="grid grid-cols-1 w-full mx-auto h-auto md:w-6/12 md:grid-cols-3">
            <div className="grid grid-cols-12 w-11/12 mt-[54px] mx-auto  h-[125px] rounded-[19px] bg-half-light-f5">
                <div className="col-span-12 h-full  flex flex-col justify-center items-center g">
                    <h1 className="text-base">จำนวนโพสต์ที่แชร์</h1>
                    <span className="text-[36px]">{myshareData.length}</span>
                </div>
{/* 
                <div className="col-span-6 h-full flex flex-col justify-center items-center">
                    <h1 className="text-base">จำนวนโพสต์ที่แชร์สำเร็จ</h1>
                    <span className="text-[36px]">XX</span>
                </div> */}
            </div>

            {myshareData.map((data, idxMain) => {
                console.log(data)
                const Lead1st = data.sharee[0]
                const second_shared = data.sharee[1]
                const thrid_shared = data.sharee[2]
                const another_shared = data.sharee
                return (
                    <div className="w-11/12 h-[300px]  mx-auto mt-[29px]" key={idxMain}>
                        {/* <div className="w-full h-10 flex justify-center items-center">
                            <img src="https://cdn-icons-png.flaticon.com/512/1021/1021218.png" alt="img_1st" className="w-8 h-8" />
                            <h1 className="text-xl ml-2.5">ต้นสาย : {Lead1st != null ?  Lead1st.uname  : 'ไม่พบ'}</h1>
                        </div> */}

                        <div className="w-full h-[242px] rounded-[16px]">
                            <img src={data.asset_image} alt="img_asset_shared" className="w-full h-full object-fit rounded-[16px]" />
                        </div>

                        {/* <div className="grid grid-cols-12 w-full mx-auto rounded-full h-20  mt-[12px] bg-half-light-f5">
                            <div className="col-span-6 flex flex-col justify-center items-center h-full w-full">
                                <h1 className="text-base">แชร์ลำดับที่ 2</h1>
                                <span className="text-xl">{second_shared != null ? second_shared.uname : 'ไม่พบ'}</span>
                            </div>

                            <div className="col-span-6 flex flex-col justify-center items-center h-full w-full">
                                <h1 className="text-base">แชร์ลำดับที่ 3</h1>
                                <span className="text-xl">{thrid_shared != null   ? thrid_shared.uname : 'ไม่พบ'}</span>
                            </div>


                        </div> */}
                        <div
                            className={`relative transition-all ease-in-out duration-500  col-span-12 w-full  ${expanded[idxMain] ? 'h-80' : 'h-12'} mt-[4px]  flex  justify-around items-center bg-half-light-f5 rounded-[20px]`}
                            onClick={() => handleDropDownListMember(idxMain)}
                        >
                            <h1 className="absolute top-3.5 left-11 cursor-pointer">{data.sharee.length} การแชร์ต่อ</h1>
                            <img
                                className="absolute top-3.5 right-10 w-5 h-5 transform transition-transform ease-in-out duration-500"
                                src={expanded ? 'https://cdn-icons-png.flaticon.com/512/2985/2985150.png' : 'https://cdn-icons-png.flaticon.com/512/2985/2985150.png'}
                                alt="arrow"
                                style={expanded[idxMain] ? { transform: 'rotate(180deg)' } : {}}
                            />

                            <div className=" w-full h-full absolute top-10 max-h-[270px] overflow-y-auto">
                                {another_shared.map((member, idx) => {
                                    return (
                                        <div key={idx} className={`${expanded[idxMain] ? 'flex justify-around items-center h-12' : 'hidden'}`}>
                                            <img src={member.profile_image} className="w-8 h-8 rounded-full" />
                                            <span className="w-[70px] text-start">{member.uname}</span>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                )
            })}



            <div className="w-full h-80"></div>
        </div>
    )
}