
import { Link } from "react-router-dom"
import axios from "axios";
import React , { useEffect, useState , useLayoutEffect} from "react";
import FormatDateTime from "../home/GridLayoutDisplay/FormatDateTime";
import Cookies from "js-cookie";
import { MdDeleteOutline } from 'react-icons/md'
import Swal from "sweetalert2";
import { BiEdit } from 'react-icons/bi'
import formatData from '../../lib/LibFunc'
import {  useLocation } from 'react-router-dom';

const MyLeadAsset = () => {
  const getToken = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  const location = useLocation();

  useEffect(() => {
    const key = `scrollPosition_${location.pathname}`;
    const storedScrollPositionString = localStorage.getItem(key) || '0';
    const storedScrollPosition = parseInt(storedScrollPositionString, 10);

    const handleScroll = () => {
      localStorage.setItem(key, window.scrollY.toString());
    };

    window.addEventListener('scroll', handleScroll);

    // use setTimeout to give some delay to scroll restoration.
    setTimeout(() => window.scrollTo(0, storedScrollPosition), 1500);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);


  
  


  const [LeadAsset, setLeadAsset] = useState([])
  const [filterTempLeadAsset, setFilterTempLeadAsset] = useState([])
  const [selectFilterLead, setSelectFilterLead] = useState(null)
  const [fetchPageNumber, setFetchPageNumber] = useState(1)
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/asset/myleads`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      const data = res.data.assets
      console.log(data)
      setLeadAsset(data)
    })
      .catch((error) => {
        // handle errors
      });

  }, [])

  const fetchData = ()=>{
    axios.get(`${process.env.REACT_APP_API}/asset/myleads`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      const data = res.data.assets
      console.log(data)
      setLeadAsset(data)
    })
      .catch((error) => {
        // handle errors
      });

  }

  const [tempDisplayRemoveLeadID , settempDisplayRemoveLeadID] = useState(null)

  function handleRemoveMyAsset(leadID) {
    
    Swal.fire({
      title: 'ยืนยันการลบโพสต์?',
      text: "คุณต้องการลบโพสต์!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor: '#000',
      confirmButtonText: 'ยืนยันการลบโพสต์'
    }).then((result) => {




      if (result.isConfirmed) {

        axios.post(`${process.env.REACT_APP_API}/event/deleteLead`, {

          lead_id: leadID

        }, {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }).then(async (res) => {
          // console.log(res.data)
          await Swal.fire(
            'สำเร็จ!',
            'ลบโพสต์สำเร็จ',
            'success'
          )

          settempDisplayRemoveLeadID(leadID)
          fetchData()
          // window.location.reload()
        }).catch((err) => {
          console.log(err)
        });
      }
    })
  }

  function formatedPhoneNumber(phonenumber) {
    if (phonenumber.length === 10) {
      const formattedNumber = phonenumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      return formattedNumber;
    } else {
      // If the length is not 10, return as is or handle the case accordingly
      return phonenumber;
    }
  }

  const dataFilter = [{
    label: 'รออนุมัติ',
    status: 'pending'
  }, {
    label: 'สำเร็จ',
    status: 'success'
  }, {
    label: 'ไม่สำเร็จ',
    status: 'failed'
  }]

  function handleFilterData(label) {
    if (selectFilterLead === label) {
      setSelectFilterLead(null);
      setFilterTempLeadAsset(LeadAsset); // Show all assets
    } else {
      setSelectFilterLead(label);
      const FilterDataShow = LeadAsset.filter(data => data.status === label);
      setFilterTempLeadAsset(FilterDataShow);
    }
}
  console.log('select filter', selectFilterLead)
  return (
    <div className="bg-white lg:snap-y lg:snap-mandatory lg:h-screen overflow-auto mt-6  w-3xl">
      <div className="mx-auto px-6 lg:px-8 relative">
        <div className="mx-auto px-6 lg:px-8 ">

          <div className="flex justify-center max-w-7xl mx-auto bg-line ">
            <div className="w-full lg:mx-0">
              <h2 className={"text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"}>Lead ของฉัน</h2>
              <span>จำนวน {filterTempLeadAsset.length == 0 ? LeadAsset.length : filterTempLeadAsset.length} โพสต์</span>
              <div className="grid grid-cols-12 gap-2">
                {dataFilter.map((data, idx) => {
                  return (
                    <div onClick={() => handleFilterData(data.status)} key={idx} className={`cursor-pointer h-[33px] col-span-4 border rounded-full text-center flex justify-center items-center mt-2.5   ${data.status == 'pending'  && selectFilterLead == 'pending'  ? 'bg-orange-500 text-white' : data.status == 'success' && selectFilterLead == 'success' ? 'bg-green-500 text-white' : data.status == 'failed' && selectFilterLead == 'failed' ? 'bg-red-500 text-white' : 'bg-half-light-f5'}`}>{data.label}</div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={"mx-auto mt-2.5 mx grid max-w-2xl grid-cols-1 md:grid-cols-2 gap-x-1 gap-y-8 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-7xl lg:mx-auto lg:grid-cols-12"} >
            {
              filterTempLeadAsset && filterTempLeadAsset.length > 0  ?
                filterTempLeadAsset.map((post) => {
                  const phoneNumber = post.phone_number;
                  const formattedPhoneNumber = formatedPhoneNumber(phoneNumber);
                  
                  return (
                    <article key={post.id} className={`${tempDisplayRemoveLeadID != post.lead_id ? 'flex flex-col' : 'hidden'} items-center justify-between w-full mx-auto md:col-span-4 lg:col-span-3 `}>
                        <div className={post.status === true ? "flex max-w-xl flex-col  opacity-30 " : "flex max-w-xl flex-col  rounded border rounded-xl p-1 mx-auto w-full"}>
                          <div className="w-full h-60 max-h-60 relative">
                            <img src={post.images_url[0]} className="min-w-full w-full h-full object-cover rounded-xl  z-10"></img>
                            <MdDeleteOutline className="absolute top-2 right-2 bg-red-500 text-white p-1 w-8 h-8 rounded-full" onClick={() => handleRemoveMyAsset(post.lead_id)} />
                            <Link to={`/edit/lead/${post.lead_id}`}>
                              <BiEdit className="absolute top-2 left-2 p-1 w-8 h-8 rounded-full bg-white" />
                            </Link>
                          </div>

                          <div className="flex items-center gap-x-4 text-xs">
                            <time className="text-gray-500">
                              {<FormatDateTime isoDateString={post.date} />}
                            </time>
                            <a
                              href={"#"}
                              className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-grawy-100 "
                            >
                              {post.id}
                            </a>
                          </div>
                          <Link to={`/product/${post.id}`}>
                            <div className="group relative ">
                              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                สถานะ : {`${post.status}`}
                              </h3>
                              <h3>เบอร์โทรศัพท์ : {`${formattedPhoneNumber}`}</h3>
                            </div>
                          </Link>
                        </div>
                      </article>
                  );
                })
                :
                LeadAsset && LeadAsset.length > 0 && selectFilterLead == null ?
                  LeadAsset.map((post) => {
                    const phoneNumber = post.phone_number;
                    const formattedPhoneNumber = formatedPhoneNumber(phoneNumber);
                    return (
                      <article key={post.lead_id} className={`${tempDisplayRemoveLeadID != post.lead_id ? 'flex flex-col' : 'hidden'} items-center justify-between w-full mx-auto md:col-span-4 lg:col-span-3 border`}>
                        <div className={post.status === true ? "flex max-w-xl flex-col  opacity-30 " : "flex max-w-xl flex-col  rounded border rounded-xl p-1 mx-auto w-full"}>

                          <div className="w-full h-60 max-h-60 relative">
                          <Link to={`/product/${post.lead_id}`}>
                            <img src={post.images_url[0]} className="min-w-full w-full h-full object-cover rounded-xl  z-10"></img>
                          </Link>

                            <MdDeleteOutline className="absolute top-2 right-2 bg-red-500 text-white p-1 w-8 h-8 rounded-full" onClick={() => handleRemoveMyAsset(post.lead_id)} />
                            <Link to={`/edit/lead/${post.lead_id}`}>
                              <BiEdit className="absolute top-2 left-2 p-1 w-8 h-8 rounded-full bg-white" />
                            </Link>
                          </div>

                          <div className="flex items-center gap-x-4 text-xs">
                            <time className="text-gray-500">
                              {<FormatDateTime isoDateString={post.date} />}
                            </time>
                            <a
                              href={"#"}
                              className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-grawy-100 "
                            >
                              {post.lead_id}
                            </a>
                          </div>
                          <Link to={`/product/${post.lead_id}`}>
                            <div className="group relative ">
                              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                สถานะ : {`${post.status}`}
                              </h3>
                              <h3>เบอร์โทรศัพท์ : {`${formattedPhoneNumber}`}</h3>
                            </div>
                          </Link>
                        </div>
                      </article>
                    )
                  })
                  :
                  <h1 className="w-full text-center col-span-12">คุณยังไม่มี Lead</h1>
            }

          </div>
        </div>
      </div>

      <div className="w-full h-[200px]"></div>
    </div>
  );
};

export default MyLeadAsset;
