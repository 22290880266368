import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function EditProfile() {
  const getToken = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  const [file, setFile] = useState(null);
  const [profileUpdate, setprofileUpdate] = useState("");
  const [showUsernameInput, setShowUsernameInput] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [profileInit, setProfileInit] = useState(null);
  const [dataUpdate, setDataUpdate] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    age: "",
    zipcode: "",
    facebook: null,
    lineId: null,
  });
  const [dataBeforeUpdate, setDataBeforeUpdate] = useState({
    age: "",
    bio: "",
    email_address: "",
    facebook: "",
    first_name: "",
    gender: "",
    last_name: "",
    line_id: "",
    phone_number: "",
    profile_image: "",
  });

  const [dataLocationUpdate, setDataLocationUpdate] = useState({
    province: "",
    amphure: "",
    tambol: "",
    zipcode: "",
  });

  const [addressData, setAddressData] = useState([]);
  const provinceArr = [];
  const [provinceData, setProvinceData] = useState([]);
  const DistrictsArr = [];
  const [districtsData, setdistrictsData] = useState([]);
  const TambonArr = [];
  const [TambonData, setTambonData] = useState([]);

  useEffect(() => {
    if (addressData !== "") {
      addressData.forEach((data) => {
        const provinceCheck = data.amphure.province.name_th;
        const DistrictCheck = data.amphure.name_th;
        const TambonCheck = data.name_th;
        if (provinceArr.indexOf(provinceCheck) === -1) {
          provinceArr.push(data.amphure.province.name_th);
        }
        if (DistrictsArr.indexOf(DistrictCheck) === -1) {
          DistrictsArr.push(data.amphure.name_th);
        }
        if (TambonArr.indexOf(TambonCheck) === -1) {
          TambonArr.push(data.name_th);
        }
      });
      setProvinceData(provinceArr);
      setdistrictsData(DistrictsArr);
      setTambonData(TambonArr);
    }
  }, [addressData]);

  const handleUsernameChange = async () => {
    const { value: newUsername } = await Swal.fire({
      title: 'กรุณากรอก username ใหม่ที่ต้องการ',
      input: 'text',
      inputPlaceholder: 'กรอกชื่อผู้ใช้ใหม่ของคุณ',
      showCancelButton: true,
      confirmButtonText: 'บันทึก',
      cancelButtonText: 'ยกเลิก',
      inputValidator: (value) => {
        if (!value) {
          return 'คุณต้องกรอก username ใหม่!';
        }
        return null;
      }
    });

    if (newUsername) {
      console.log("New username:", newUsername);
      setShowUsernameInput(false);
    }
  };

  const handleFileUpload = async (event) => {
    const ImageUpload = event.target.files[0];
    const formData = new FormData();
    formData.append("image", ImageUpload);
    try {
      await axios.put(`${process.env.REACT_APP_API}/update/profile-image`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setProfileInit(ImageUpload);
      Swal.fire("Success", "Profile picture updated!", "success");
    } catch (err) {
      console.log(err);
    }
  };

  const callDataBeforeUpdate = async () => {
    const cookie = Cookies.get("jwt");
    const token = `${cookie}`;
    await axios
      .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const {
          age,
          bio,
          email_address,
          facebook,
          first_name,
          gender,
          last_name,
          line_id,
          phone_number,
          profile_image,
          zipcode,
          province,
          amphure,
          tambol,
        } = res.data;
        setDataBeforeUpdate({
          age: age,
          bio: bio,
          email_address: email_address,
          facebook: facebook,
          first_name: first_name,
          gender: gender,
          last_name: last_name,
          line_id: line_id,
          phone_number: phone_number,
          profile_image: profile_image,
          zipcode: zipcode,
          province: province,
          amphure: amphure,
          tambon: tambol,
        });

        setDataUpdate({
          first_name: first_name,
          last_name: last_name,
          gender: gender,
          age: age,
          facebook: facebook,
          lineId: line_id,
        });
        setGenderSelected(gender);
        setprofileUpdate(profile_image);
      });
  };

  useEffect(() => {
    callDataBeforeUpdate();
  }, []);

  const inputValue = (name) => (e) => {
    setDataUpdate({ ...dataUpdate, [name]: e.target.value });
  };

  const [genderSelected, setGenderSelected] = useState(dataBeforeUpdate.gender);

  const genderHandle = (gender) => {
    setGenderSelected(gender);
    setDataUpdate({ ...dataUpdate, gender: gender });
  };

  const callZipcode = async (zipcodeRaw) => {
    console.log(zipcodeRaw);
    if (zipcodeRaw.length === 5) {
      axios
        .post(`${process.env.REACT_APP_API}/fetchData/getQueryLocation`, {
          zipcode: zipcodeRaw,
        })
        .then((res) => {
          console.log(res);
          setAddressData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateGeneralData = () => {
    axios
      .put(
        `${process.env.REACT_APP_API}/update/profile-data`,
        {
          age: dataUpdate.age,
          firstName: dataUpdate.first_name,
          lastName: dataUpdate.last_name,
          bio: "test",
          email: "",
          gender: dataUpdate.gender,
          lineId: dataUpdate.lineId,
          facebookUrl: dataUpdate.facebook,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(async (res) => {
        await Swal.fire("อัพเดทข้อมูลเรียบร้อย!", "กำลังพาคุณไปหน้าโปรไฟล์..", "success");
        window.location.replace("/profiles");
      })
      .catch((err) => {
        Swal.fire("เกิดข้อผิดพลาด!", `${err.response.data.Error}`, "error");
      });
  };

  const updateLocatoinData = () => {
    axios
      .put(`${process.env.REACT_APP_API}/update/profile-location`, dataLocationUpdate, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        Swal.fire("เกิดข้อผิดพลาด!", `${err.response.data.Error}`, "error");
      });
  };

  function updateProfile(e) {
    e.preventDefault();
    console.log("OK");
    updateGeneralData();
    updateLocatoinData();
  }

  const handleProvinceUpdate = (e) => {
    const provinceSelect = e.target.value;
    setDataLocationUpdate({ ...dataLocationUpdate, province: provinceSelect });
  };

  const handleAmphureUpdate = (e) => {
    const amphureSelect = e.target.value;
    setDataLocationUpdate({ ...dataLocationUpdate, amphure: amphureSelect });
  };

  const handleTambonUpdate = (e) => {
    const tambonSelect = e.target.value;
    setDataLocationUpdate({ ...dataLocationUpdate, tambol: tambonSelect });
  };

  return (
    <motion.div
      className="min-h-screen flex items-center justify-center p-4 bg-gradient-to-b from-white to-blue-100"
    >
      {getToken() !== undefined ? (
        <motion.div
          className="w-full max-w-4xl p-6 bg-white rounded-lg shadow-lg"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <form onSubmit={updateProfile} className="space-y-8">
            <div className="flex items-center justify-between">
              <Link to="/profiles" className="text-blue-500 hover:text-blue-700">
                <img src="https://cdn-icons-png.flaticon.com/512/271/271220.png" alt="back" className="w-8 h-8" />
              </Link>
              <h1 className="text-2xl font-semibold text-gray-700">แก้ไขโปรไฟล์</h1>
            </div>

            <div className="flex justify-center">
              <div className="relative w-28 h-28">
                <img
                  src={
                    profileUpdate !== ""
                      ? profileUpdate
                      : profileInit !== null
                        ? URL.createObjectURL(profileInit)
                        : "https://cdn-icons-png.flaticon.com/512/9460/9460908.png"
                  }
                  alt="Profile"
                  className="rounded-full w-full h-full object-cover border-2 border-white"
                />
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handleFileUpload}
                />
                <div className="absolute bottom-0 right-0 bg-white p-1 rounded-full shadow">
                  <img src="https://cdn-icons-png.flaticon.com/512/44/44413.png" alt="edit" className="w-6 h-6" />
                </div>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-medium mb-4">ข้อมูลส่วนตัว</h2>
              <div className="space-y-4">
                <div>

                  <label className="block text-sm font-medium text-gray-700">
                    ชื่อ <span className="text-xs text-gray-500">(ไม่จำเป็น)</span>
                  </label>

                  <input
                    type="text"
                    placeholder="ชื่อ"
                    defaultValue={dataBeforeUpdate.first_name}
                    onChange={inputValue("first_name")}
                    className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    นามสกุล <span className="text-xs text-gray-500">(ไม่จำเป็น)</span>
                  </label>
                  <input
                    type="text"
                    placeholder="นามสกุล"
                    defaultValue={dataBeforeUpdate.last_name}
                    onChange={inputValue("last_name")}
                    className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">เพศ</label>
                  <div className="flex space-x-4 mt-2">
                    <button
                      type="button"
                      onClick={() => genderHandle("male")}
                      className={`w-full p-2 rounded-md ${genderSelected === "male" ? "bg-blue-500 text-white" : "bg-gray-200"
                        }`}
                    >
                      ชาย
                    </button>
                    <button
                      type="button"
                      onClick={() => genderHandle("female")}
                      className={`w-full p-2 rounded-md ${genderSelected === "female" ? "bg-pink-500 text-white" : "bg-gray-200"
                        }`}
                    >
                      หญิง
                    </button>
                    <button
                      type="button"
                      onClick={() => genderHandle("lgbt")}
                      className={`w-full p-2 rounded-md ${genderSelected === "lgbt" ? "bg-purple-500 text-white" : "bg-gray-200"
                        }`}
                    >
                      ไม่ระบุ / LGBTQ+
                    </button>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                      อายุ <span className="text-xs text-gray-500">(ขั้นต่ำ 18ปี)</span>
                    </label>
                  <div className="flex items-center space-x-2 mt-1">
                    <button
                      type="button"
                      onClick={() => setDataUpdate({ ...dataUpdate, age: Math.max(dataUpdate.age - 1, 18) })}
                      className="p-2 bg-gray-200 rounded-md"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={dataUpdate.age}
                      onChange={(e) =>
                        setDataUpdate({
                          ...dataUpdate,
                          age: Math.max(18, Math.min(90, parseInt(e.target.value) || 18)),
                        })
                      }
                      className="w-full p-2 border rounded-md text-center bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                      min="18"
                      max="90"
                    />
                    <button
                      type="button"
                      onClick={() => setDataUpdate({ ...dataUpdate, age: Math.min(dataUpdate.age + 1, 90) })}
                      className="p-2 bg-gray-200 rounded-md"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <motion.button
                    type="button"
                    onClick={handleUsernameChange}
                    className="py-2 w-full bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    เปลี่ยนชื่อผู้ใช้
                  </motion.button>
                </div>

              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-medium mb-4">ข้อมูลที่อยู่</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">รหัสไปรษณีย์</label>
                  <input
                    type="number"
                    placeholder="กรุณากรอกเลขไปรษณีย์ก่อนเลือกจังหวัด"
                    defaultValue={dataBeforeUpdate.zipcode}
                    onChange={(e) => {
                      inputValue("zipcode")(e);
                      callZipcode(e.target.value);
                      setDataLocationUpdate({ ...dataLocationUpdate, zipcode: e.target.value });
                    }}
                    className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">จังหวัด</label>
                  <select
                    onChange={handleProvinceUpdate}
                    className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option>{dataBeforeUpdate.province}</option>
                    {provinceData.map((province) => (
                      <option key={province} value={province}>
                        {province}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">อำเภอ</label>
                  <select
                    onChange={handleAmphureUpdate}
                    className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option>{dataBeforeUpdate.amphure}</option>
                    {districtsData.map((district) => (
                      <option key={district} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">ตำบล</label>
                  <select
                    onChange={handleTambonUpdate}
                    className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option>{dataBeforeUpdate.tambon}</option>
                    {TambonData.map((tambon) => (
                      <option key={tambon} value={tambon}>
                        {tambon}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-medium mb-4">โซเชียลมีเดีย</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Facebook</label>
                  <input
                    type="text"
                    defaultValue={dataBeforeUpdate.facebook}
                    onChange={inputValue("facebook")}
                    className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Line ID</label>
                  <input
                    type="text"
                    defaultValue={dataBeforeUpdate.line_id}
                    onChange={inputValue("lineId")}
                    className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-6">
              <button
                type="submit"
                className="w-full max-w-md py-3 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600"
              >
                ยืนยันการแก้ไข
              </button>
            </div>
          </form>
        </motion.div>
      ) : (
        <div>{window.location.replace("/login")}</div>
      )}
    </motion.div>
  );
}

export default EditProfile;
